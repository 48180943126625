import { useContext, useState } from 'react';
import { Button, Header, Icon, Image, Menu } from 'semantic-ui-react';
import { LocalStorage } from '../Service/LocalStorage';
import NavigationList from './NavigationList';
function AppHeader() {
    const mobileSize = '(max-width: 600px)';
    const tabSize = '(max-width: 850px)';
    const { isSideMenuActive: [, setIsSideMenuActive] } = useContext(LocalStorage);
    const [isTabSize, setIsTabSize] = useState(window.matchMedia(tabSize).matches);
    const [isMobileSize, setIsMobileSize] = useState(window.matchMedia(mobileSize).matches);
    window.onresize = function () {
        setIsTabSize(window.matchMedia(tabSize).matches);
        setIsMobileSize(window.matchMedia(mobileSize).matches);
        setIsSideMenuActive(false);
    }
    return (
        <>
            <div className='websiteHeader' id='header'>
                <Image src="https://api.cnmsrgblab.com/media/images/logo.svg" alt='' className='websiteLogo' onClick={() => window.location.href = '/home'} />
                <Header as='h1' className='websiteTitle'>RGB's Photo & Electrocatalysis Lab</Header>
                <Button content='Contact Us' primary onClick={() => window.location.href = '/contact'}/>
            </div>
            {!isTabSize &&
                <Menu className='ui menu appHeader'>
                    <NavigationList></NavigationList>
                </Menu>
            }
            {isTabSize &&
                <Menu className='ui menu appHeader sideMenu'>
                    <Icon name='bars' size='large' className='headerSideIcon' onClick={() => {setIsSideMenuActive(true);document.getElementById('header').scrollIntoView()}} />
                    <Header as={isMobileSize ? 'h3' : 'h1'} className='websiteTitleHeaderMenu'>RGB's Photo & Electrocatalysis Lab</Header>
                </Menu>
            }
        </>
    );
}

export default AppHeader;
